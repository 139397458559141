import { Environment } from "./environment-type";

export const environment: Environment = {
	kind: 'feature',
	production: false,
	instance: 'US',
	googleApiKey: 'AIzaSyCfg8LONCPd5oaeOquvWFuzaf9OOGWD5JU',
	webApiUrl: 'https://web-api.feature-us.taradel.com',
	creditKeyPublicKey: 'taradel_316ac48f39a645a48d98542d41057fbd',
	creditKeyEnvironment: 'staging',
	identity: {
		authority: 'https://identity.dev-us.taradel.com',
		responseType: 'id_token token',
		scope: 'openid profile taradel-web-api customer.profile offline_access',
	},
	posthog: {
		key: 'phc_iH2VHyfNfSzfVsIVhCGvvH1UjzOKnwClsXEFMK4gRDG',
		host: 'https://us.i.posthog.com',
	},
	datadog: {
		clientToken: 'pub5ee6c764a02114b9de4ef476cc0ca6b5',
		applicationId: 'c305f20d-65c5-481b-9b8c-efa0424f965e',
		site: 'datadoghq.com',
	},
	getEnvString: () => {
		return `${environment.kind}:${environment.instance}`.toLowerCase();
	},
};
